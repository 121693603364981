import React, {useState} from 'react';

import './ProfileCompany.scss';
import { useTranslation } from 'react-i18next';

const ProfilePlansSuccess = () => {
  const {t} = useTranslation();

  return (
    <div className='zestur-card'>
      <h2 className='general-heading mt-4 mb-4'>{t('planPage.successheading')}</h2>
      <p>{t('planPage.successbody')}</p>
      <p>&nbsp;</p>
    </div>
  );
};

export default ProfilePlansSuccess;
