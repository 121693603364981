import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomeCompany from '../views/company/home/HomeCompany';
import CompanyLayout from '../layouts/company/CompanyLayout';
import CandidateLayout from '../layouts/candidate/CandidateLayout';
import HomeCandidate from '../views/candidate/home/HomeCandidate';
import SignIn from '../views/Auth/SignIn';
import SignInCandidate from '../views/Auth/candidate/SignIn';
import SignUp from '../views/Auth/SignUp';
import SignUpCandidate from '../views/Auth/candidate/SignUp';
import BlankLayout from '../layouts/BlankLayout';
import ApplicantsCompany from '../views/company/applicants/ApplicantsCompany';
import ChatCompany from '../views/company/chat/ChatCompany';
import VacanciesCompany from '../views/company/vacancies/VacanciesCompany';
import ProfileCompany from '../views/company/profile/ProfileCompany';
import CompanyApplicantDetail from '../views/company/applicants/applicant-detail/CompanyApplicantDetail';
import ProfileDetailCompany from '../views/company/profile/ProfileDetailCompany';
import ProfilePlansCompany from '../views/company/profile/ProfilePlansCompany';
import VacancyDetailCompany from '../views/company/vacancies/detail/VacancyDetailCompany';
import VacancyEditCompany from '../views/company/vacancies/edit/VacancyEdit';
import Offer from '../views/company/offers/Offer';
import ProfilePlansDetailCompany from '../views/company/profile/ProfilePlansDetailCompany';
import ProfilePlansDetailCompanyAnnual from '../views/company/profile/ProfilePlansDetailCompanyAnnual';
import ProfilePlansDetailCompanySmart from '../views/company/profile/ProfilePlansDetailCompanySmart';
import ProfilePlansSuccess from '../views/company/profile/ProfilePlansSuccess';
import ProfilePlansFailure from '../views/company/profile/ProfilePlansFailure';
import ChatCandidate from '../views/candidate/chat/ChatCandidate';
import ApplicantDetailCandidate from '../views/candidate/applicant-detail/ApplicantDetail';
import Blog from '../views/candidate/blog/Blog';
import HomeCandidateMain from '../views/candidate/home-main/HomeCandidateMain';
import VacancyDetails from '../components/vacancy-details/VacancyDetails';
import RequestComponent from '../views/candidate/request/Request';
import VacancyLocation from '../components/vacancy-location/VacancyLocation';
import ResetPassword from '../components/reset-password/ResetPassword';
import HomeComponent from '../views/home/Home';
import EmailActivation from '../components/email-activation/EmailActivation';
import ActivationMessageCheck from '../components/activation-message-check/ActivationMessageCheck';
import { LinkedInCallback } from 'react-linkedin-login-oauth2';
import WithNotificationWebsocket from '../hoc/WithNotificationWebsocket';

import HomeAdmin from '../views/admin/home/HomeAdmin';

const AppRouting = () => {
  return (
    <Routes>
      <Route path='/' element={<HomeComponent />} />
      <Route
        path='/auth/company/sign-in'
        element={<ActivationMessageCheck component={<SignIn />} />}
      />
      <Route path='/auth/company/sign-up' Component={SignUp} />
      <Route
        path='/auth/candidate/sign-in'
        element={<ActivationMessageCheck component={<SignInCandidate />} />}
      />
      <Route path='/auth/candidate/sign-up' Component={SignUpCandidate} />
      <Route
        path='/password/reset/confirm/:resetToken/:userId'
        Component={ResetPassword}
      ></Route>
      <Route
        path='/activate/:token/:userId'
        Component={EmailActivation}
      ></Route>
      <Route path='/linkedin' Component={LinkedInCallback} />

      <Route
        path='/company'
        element={
          <WithNotificationWebsocket>
            <CompanyLayout />
          </WithNotificationWebsocket>
        }
      >
        <Route path='' element={<HomeCompany />} />
        <Route path='/company/applicants' element={<ApplicantsCompany />} />
        <Route
          path='/company/applicants/:candidateId/:offerId'
          element={<CompanyApplicantDetail />}
        />
        <Route path='/company/chat' element={<ChatCompany />} />
        {/* Vacancies routes */}
        <Route path='/company/vacancies' element={<VacanciesCompany />} />
        <Route
          path='/company/vacancies/:id/detail'
          element={<VacancyDetailCompany />}
        />
        <Route
          path='/company/vacancies/:id/edit'
          element={<VacancyEditCompany />}
        />
        {/* Profile routes */}
        <Route path='/company/profile' element={<ProfileCompany />}>
          <Route path='' element={<ProfileDetailCompany />} />
          <Route
            path='/company/profile/plans'
            element={<ProfilePlansCompany />}
          />
        </Route>
        <Route
          path='/company/profile/plans/detail'
          element={<ProfilePlansDetailCompany />}
        />
        <Route
          path='/company/profile/plans/annual'
          element={<ProfilePlansDetailCompanyAnnual />}
        />
        <Route
          path='/company/profile/plans/smart'
          element={<ProfilePlansDetailCompanySmart />}
        />
        <Route
          path='/company/profile/plans/success'
          element={<ProfilePlansSuccess />}
        />
        <Route
          path='/company/profile/plans/failure'
          element={<ProfilePlansFailure />}
        />
        {/* Publish offers */}
        <Route path='/company/offers' element={<Offer />} />
      </Route>

      {/* candidate routes */}
      <Route
        path='/candidate'
        element={
          <WithNotificationWebsocket>
            <CandidateLayout />
          </WithNotificationWebsocket>
        }
      >
        <Route path='' element={<HomeCandidate />}>
          <Route path='' element={<HomeCandidateMain />} />
          <Route path=':vacancyId' element={<VacancyDetails />}></Route>
          <Route
            path=':vacancyId/location'
            element={<VacancyLocation />}
          ></Route>
          <Route path='/candidate/blogs' element={<Blog />} />
          <Route path='/candidate/request' element={<RequestComponent />} />
        </Route>
        <Route path='/candidate/chat' element={<ChatCandidate />} />
        <Route
          path='/candidate/applicants/detail'
          element={<ApplicantDetailCandidate />}
        />
      </Route>

      <Route path='/admin' element={<HomeAdmin />} />

      <Route path='/*' element={<BlankLayout />} />
    </Routes>
  );
};

export default AppRouting;
