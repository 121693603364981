import React, { useState, useEffect, useContext } from 'react';

import './ProfileCompany.scss';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Image, Modal, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

import icDone from '../../../assets/images/ic_done.svg';

import { ASSETS_URL } from '../../../constants';
import { useTranslation } from 'react-i18next';

const ProfilePlansDetailCompanyAnnual = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showFinalModal, setShowFinalModal] = useState(false);

  const handleCloseFinalModal = () => setShowFinalModal(false);
  const handleShowFinalModal = () => setShowFinalModal(true);
  const benefits: string[] = t('planDetails.card.benefits', {
    returnObjects: true,
  });

  return (
    <>
      <div className='zestur-card'>
        <div className='d-flex align-items-center justify-content-between'>
          <h2 className='general-heading fw-semibold'>
            {t('planDetails.card.title')}
          </h2>
          <h1 className='fw-bold m-0'>250 €</h1>
        </div>
        <hr />

        <p className='general-heading'>{t('planDetails.card.benefitsTitle')}</p>
        <ul className='mt-3 f-12'>
          {benefits.map((item) => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
      <div className='zestur-card mt-4'>
        <Row>
          <Col md={6}>
            <div className='d-flex align-items-center justify-content-between'>
              <h2 className='general-heading fw-semibold'>
                {t('planDetails.form.title')}
              </h2>
            </div>
            <hr />
            <Form className='mt-4'>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='fw-bold mb-2'>
                  {t('planDetails.form.cardNameLabel')}
                </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-gray'
                  placeholder={t('planDetails.form.cardNamePlaceholder')}
                />
              </Form.Group>
              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <Form.Label className='fw-bold mb-2'>
                  {t('planDetails.form.cardNumberLabel')}
                </Form.Label>
                <Form.Control
                  type='text'
                  className='form-control-gray'
                  placeholder='1234  5678  9101  1121'
                />
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='fw-bold mb-2'>
                      {t('planDetails.form.expirationLabel')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-gray'
                      placeholder='MM/YY'
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className='mb-3'
                    controlId='exampleForm.ControlInput1'
                  >
                    <Form.Label className='fw-bold mb-2'>
                      {t('planDetails.form.cvvLabel')}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-gray'
                      placeholder='123'
                    />
                  </Form.Group>
                </Col>
              </Row>
              <button
                className='btn btn-primary primary-btn-color w-100 py-3 mt-4'
                type='button'
                onClick={handleShowFinalModal}
              >
                {t('planDetails.form.pay')} 250,00 €
              </button>
              <p className='general-sub-heading mt-4'>
                {t('planDetails.form.paymentDescription')}
              </p>
            </Form>
          </Col>
          <Col md={6}>
            <div className='d-flex align-items-center justify-content-between'>
              <h2 className='general-heading fw-semibold'>
                {t('planDetails.formInfo.title')}
              </h2>
            </div>
            <hr />
            <div className='d-flex align-items-center justify-content-between mt-5'>
              <h2 className='general-sub-heading fw-semibold text-dark'>
                {t('planDetails.formInfo.subtitle')}
              </h2>
              <h2 className='general-sub-heading fw-semibold text-dark'>
                250 €
              </h2>
            </div>
            <h2 className='general-sub-heading mt-2 mb-4'>
              {t('planDetails.formInfo.planSubtitle')}
            </h2>
            <hr />
            <Row className='mt-4 mb-4'>
              <Col md={9}>
                <Form.Group controlId='exampleForm.ControlInput1'>
                  <Form.Control
                    type='text'
                    className='form-control-gray'
                    placeholder={t('planDetails.formInfo.discountPlaceholder')}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <button className='btn btn-success w-100 py-2'>
                  {t('planDetails.formInfo.discountButton')}
                </button>
              </Col>
            </Row>
            <hr />
            <div className='d-flex align-items-center justify-content-between mt-5'>
              <h2 className='general-sub-heading fw-semibold text-dark'>
                {t('planDetails.formInfo.subtotal')}
              </h2>
              <h2 className='general-sub-heading fw-semibold text-dark'>
                250 €
              </h2>
            </div>
            <hr />
            <div className='d-flex align-items-center justify-content-between mt-5'>
              <h2 className='general-sub-heading fw-semibold text-dark'>
                {t('planDetails.formInfo.total')}
                <h2 className='general-sub-heading mt-2'>
                  {t('planDetails.formInfo.includingVatTitle')}
                </h2>
              </h2>
              <h2 className='general-sub-heading fw-semibold text-dark'>
                250 €
              </h2>
            </div>
          </Col>
        </Row>
      </div>

      <Modal show={showFinalModal} centered onHide={handleCloseFinalModal}>
        <Modal.Body className='text-center py-5'>
          <Image className='mt-5' src={ASSETS_URL + icDone} />
          <h1 className='fw-bold my-4'>{t('planDetails.modal.title')}</h1>
          <p className='f-12 mt-4'>{t('planDetails.modal.description')}</p>
          <button
            className='btn mt-4 mb-5 btn-primary primary-btn-color px-5 py-3'
            onClick={() => navigate('/company')}
          >
            {t('planDetails.modal.acceptButton')}
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ProfilePlansDetailCompanyAnnual;
